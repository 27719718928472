import { default as connexionNhsfnOzZGpMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as donnees_45personnellesIPGeeJpNV5Meta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93MQ4kJEdYX9Meta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/pages/download/[k].vue?macro=true";
import { default as indexNBtiGzYu6OMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/pages/index.vue?macro=true";
import { default as mentions_45legalesNMi4L9NPIoMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91token_93BwM15BBsvYMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexwJjEVQVmcXMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieHAymBAQbwKMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as indexyTEYn0w2mWMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profil5JxTX1zWbTMeta } from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil.vue?macro=true";
export default [
  {
    name: connexionNhsfnOzZGpMeta?.name ?? "connexion",
    path: connexionNhsfnOzZGpMeta?.path ?? "/connexion",
    meta: connexionNhsfnOzZGpMeta || {},
    alias: connexionNhsfnOzZGpMeta?.alias || [],
    redirect: connexionNhsfnOzZGpMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: donnees_45personnellesIPGeeJpNV5Meta?.name ?? "donnees-personnelles",
    path: donnees_45personnellesIPGeeJpNV5Meta?.path ?? "/donnees-personnelles",
    meta: donnees_45personnellesIPGeeJpNV5Meta || {},
    alias: donnees_45personnellesIPGeeJpNV5Meta?.alias || [],
    redirect: donnees_45personnellesIPGeeJpNV5Meta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: _91k_93MQ4kJEdYX9Meta?.name ?? "download-k",
    path: _91k_93MQ4kJEdYX9Meta?.path ?? "/download/:k()",
    meta: _91k_93MQ4kJEdYX9Meta || {},
    alias: _91k_93MQ4kJEdYX9Meta?.alias || [],
    redirect: _91k_93MQ4kJEdYX9Meta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/pages/download/[k].vue").then(m => m.default || m)
  },
  {
    name: indexNBtiGzYu6OMeta?.name ?? "index",
    path: indexNBtiGzYu6OMeta?.path ?? "/",
    meta: indexNBtiGzYu6OMeta || {},
    alias: indexNBtiGzYu6OMeta?.alias || [],
    redirect: indexNBtiGzYu6OMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesNMi4L9NPIoMeta?.name ?? "mentions-legales",
    path: mentions_45legalesNMi4L9NPIoMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesNMi4L9NPIoMeta || {},
    alias: mentions_45legalesNMi4L9NPIoMeta?.alias || [],
    redirect: mentions_45legalesNMi4L9NPIoMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oublieHAymBAQbwKMeta?.name ?? undefined,
    path: mot_45de_45passe_45oublieHAymBAQbwKMeta?.path ?? "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieHAymBAQbwKMeta || {},
    alias: mot_45de_45passe_45oublieHAymBAQbwKMeta?.alias || [],
    redirect: mot_45de_45passe_45oublieHAymBAQbwKMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: _91token_93BwM15BBsvYMeta?.name ?? "mot-de-passe-oublie-token",
    path: _91token_93BwM15BBsvYMeta?.path ?? ":token()",
    meta: _91token_93BwM15BBsvYMeta || {},
    alias: _91token_93BwM15BBsvYMeta?.alias || [],
    redirect: _91token_93BwM15BBsvYMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: indexwJjEVQVmcXMeta?.name ?? "mot-de-passe-oublie",
    path: indexwJjEVQVmcXMeta?.path ?? "",
    meta: indexwJjEVQVmcXMeta || {},
    alias: indexwJjEVQVmcXMeta?.alias || [],
    redirect: indexwJjEVQVmcXMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profil5JxTX1zWbTMeta?.name ?? undefined,
    path: profil5JxTX1zWbTMeta?.path ?? "/profil",
    meta: profil5JxTX1zWbTMeta || {},
    alias: profil5JxTX1zWbTMeta?.alias || [],
    redirect: profil5JxTX1zWbTMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: indexyTEYn0w2mWMeta?.name ?? "profil",
    path: indexyTEYn0w2mWMeta?.path ?? "",
    meta: indexyTEYn0w2mWMeta || {},
    alias: indexyTEYn0w2mWMeta?.alias || [],
    redirect: indexyTEYn0w2mWMeta?.redirect,
    component: () => import("/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  }
]

import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "a7404fa9-7fe7-4afc-9a18-d45061968a1b"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/9542779118/pildom/APPS/NUXT_PORTAIL/NUXT_COMMON/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
